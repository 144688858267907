<template>
  <div class="wrap">
    <Loading v-show="$store.state.isLoading"/>
    <van-form v-show="!$store.state.isLoading" @submit="onSubmit" input-align="right" error-message-align="right" label-width='4.5em'>
      <van-field
        readonly
        v-model="userName"
        name="userName"
        label="姓名"
        placeholder="姓名"
      />
      <van-field
        v-if="columns.length =='1'"
        autosize
        readonly
        v-model="contractNum"
        name="contractNum"
        label="合同号"
      />
      <van-field
        v-if="columns.length !='1'"
        autosize
        readonly
        clickable
        name="contractNum"
        :value="contractNum"
        label="合同号"
        @click="showContractPicker = true"
        right-icon="arrow-down"
      />
      <van-popup v-model="showContractPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onContractConfirm"
          @cancel="showContractPicker = false"
        />
      </van-popup>
      <van-field
        readonly
        v-model="vin"
        name="vin"
        label="车架号"
        placeholder="车架号"
      />
      <van-field
        v-if="isReadonly"
        readonly
        v-model="date"
        name="date"
        label="转账时间"
        placeholder="转账时间"
      />
      <van-field
        v-if="!isReadonly"
        readonly
        clickable
        name="date"
        :value="date"
        label="转账时间"
        placeholder="请选择选择日期"
        @click="showCalendar = true"
        right-icon="arrow-down"
        :rules="[{ required: true}]"
      />
      <van-calendar
        :min-date="minDate"  
        v-model="showCalendar" 
        @confirm="onDateConfirm" 
      />
      <van-field
        rows="1"
        autosize
        type="textarea"
        :readonly="isReadonly"
        v-model="accountName"
        name="accountName"
        :formatter="formatterAccountName"
        label="转账户名"
        maxlength="20"
        placeholder="请输入您的姓名"
        :rules="[{ required: true}]"
      />
      <van-field
        :readonly="isReadonly"
        v-model="amount"
        name="amount"
        :formatter="formatterAmount"
        label="转账金额"
        placeholder="请输入您的转账金额"
        type="number"
        :rules="[
          { required: true},
          {validator:validatorAmount1,message: '请填写正确的格式'},
          {validator:validatorAmount2,message: '请输入2位小数'}
        ]"
      >
        <template #button>
          <span type="default" size="small">元</span>
        </template>
      </van-field>
      <van-field
        v-model="upFile"
        name="upFile"  
        label="附件上传" 
        right-icon="arrow"
        :rules="[{ validator, message: '请选择附件' }]">
        <template #input >
          <span class="spanUpfile" v-if="upFile=='0'" type="default" size="small" @click="handdleUpfile">未上传</span>
          <span class="spanUpfile" v-else type="default" size="small" @click="handdleUpfile">已上传</span>
        </template>
      </van-field>
      <van-field
        v-if="isReadonly"
        v-model="currentTime"
        type="currentTime"
        name="currentTime"
        label="申请时间"
        readonly
      />
      <div class="tips">
        <p>温馨提示：</p>
        <p>1、请于当天15:00前申请，15:00后申请则会顺延到次日。 </p>
        <p>2、申请进度可在【我的申请】中查看</p>
      </div>
      <van-col span="20" offset="2" style="position:fixed;bottom:10px;">
        <van-button :disabled="isReadonly" round block type="danger" native-type="submit">
        提交
        </van-button>
      </van-col>
    </van-form>
  </div>
</template>
<script>
  import moment from 'moment';
  import { Dialog,Toast } from 'vant';
  import {corporateRepaymentDetail,corporateRepaymentSub,upFileShow,upFileSave,getOpenId} from '@/server'
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      isReadonly:false,//已提交过后字段是否可编辑
      cardId:this.$store.state.cardId,
      accessToken:this.$store.state.accessToken,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      backFlag: this.$store.state.backFlag,//是不是isReadonly返回1:false,0:true
      backFlagNum: this.$store.state.backFlagNum,//上传图片页返回 contractNum
      userName: '',
      contractNum: '',
      vin: '',
      date: '',
      accountName: '',
      amount: '',
      upFile:'',
      fileList: [],
      currentTime:'',
      showContractPicker: false,
      showCalendar: false,
      columns: [],
      dataList:[],
      imgtype:'com_account_base',//附件展示标志
      wxid:'',
      minDate: new Date(1990, 0, 1),
    };
  },
  mounted(){
    let that = this;
    sessionStorage.setItem('key', 'corpor2');
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    	if(sessionStorage.getItem('key') == 'corpor2') {
        that.$router.push({ path: '/personalCenter' });
      }
    }, false);
  },
  created(){
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          // localStorage.setItem("openId", res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          setCookie('openId',res.openid);
          this.cardId = res.card_id;
          this.accessToken = res.access_token;
          this.openId = res.openid;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/corporateRepayment' });
          }else{
            this.getFlagDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/corporateRepayment');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            // localStorage.setItem("openId", res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.cardId = res.card_id;
            this.accessToken = res.access_token;
            this.openId = res.openid;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/corporateRepayment' });
            }else{
              this.getFlagDetail();
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          } 
        });
      }
    }
    
  },
  methods: {
    getFlagDetail(){
      if(this.backFlag =="1"){ //backFlag为从上传图片页返回的时候所携带的合同号
        this.columns=[];
        var res = this.$store.state.corporateDataList;
        this.dataList = res;
        res.map(item => {
          this.columns = [...this.columns,item.con_no]
        });
        var imgUrlLength = this.$store.state.imgUrlList.length;
        this.upFile = imgUrlLength;
        var params = this.$store.state.writeData;//已经填写没有提交的数据
        this.contractNum = this.backFlagNum;
        this.userName = params.userName;
        this.vin = params.vin;
        this.accountName = params.accountName;
        this.amount = params.amount;
        this.date = params.date;
        var myDate = new Date();
        this.currentTime = this.formatDate(myDate.getTime(),true);
        this.$store.commit('backFlagNum', '');
        this.$store.commit('backFlag', '');
      }else if(this.backFlag =="0"){
        corporateRepaymentDetail({access_token:this.accessToken,card_id:this.cardId}).then(res=>{
          this.columns = [];
          this.dataList = res.detail_data;
          res.detail_data.map(item => {
            this.columns = [...this.columns,item.con_no]
          });
          this.$store.commit('corporateDataList', res.detail_data);
          this.onContractConfirm(this.backFlagNum);
          this.$store.commit('backFlagNum', '');
          this.$store.commit('backFlag', '');
        })
      }else{
        this.getDetail();
      }
    },
    validator(val) {
      if(val=='0'){
        return false;
      }
    },
    validatorAmount1(val) {
      var i = val.substring(val.indexOf(".")+1).length;
      if(val.indexOf(".")== 0){//首位不能为小数点
        return false; 
      }else if(val!='0' && val.indexOf(".") == -1 && val.indexOf(0) == 0){//没有小数点且首位不能为0
        return false;
      }
    },
    validatorAmount2(val) {
      var i = val.substring(val.indexOf(".")+1).length;
      if(val.indexOf(".")>0 && i>2){//小数点后两位
        return false;
      }
    },
    formatterAccountName(value){
      //不允许填写空格、特殊字符等；
      // return value.replace(/\s+/g,'');
      return value.replace(/[\s@#￥\$%\^&\*]+/g,'');
    },
    formatterAmount(value) {
      // 输入内容只能为数字，不允许输入字母、空格、特殊符号等； 
      return value.replace(/[^0-9.]/g,"").replace(/(\.)(\d*)(\1*)/g,"$1$2");
      // return value.replace(/[^\d+(,\d\d\d)*.\d+$]/g,'')
    },
    formatDate(date, flag) {
      if (!date) return '--';
      if (flag) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    getLastDay(year, month){
      var d = new Date(0);
      if (month == 12){
        d.setUTCFullYear(year + 1);
        d.setUTCMonth(0);
      }else{
        d.setUTCFullYear(year);
        d.setUTCMonth(month);
      }
      d.setTime(d.getTime() - 1);
      return d.getUTCDate();
    },
    formatter(day) {
      const year = day.date.getFullYear();
      const month = day.date.getMonth() + 1;
      const date = day.date.getDate();
      const lastDay = this.getLastDay(year,month);
      if (date === lastDay) {
        day.type = 'disabled'
      }
      return day;
    },
    getDetail(){
      this.columns=[];
      var myDate = new Date();
      this.date = this.formatDate(myDate.getTime());
      this.currentTime = this.formatDate(myDate.getTime(),true);
      //信息返显
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId
      }
       let res = this.$store.state.dataListTotal;
      // corporateRepaymentDetail(params).then(res=>{
        this.dataList = res;
        res.map(item => {
          this.columns = [...this.columns,item.con_no]
        });
        this.$store.commit('corporateDataList', res);
        var data = res[0];
        if(data.wx_code == '0'){//未提交
          this.userName = data.cus_name;
          this.vin = data.vin;
          this.contractNum = this.columns[0];
          this.wxid = data.wxid;
          this.upFile = '0';
        }else if(data.wx_code == '5'){//已提交,信息返显且不可修改
          this.showDataDisable(data);
          this.showImg();
        }else{
          Toast.fail({
            message: data.msg,
            duration:'3000'
          });
          this.upFile = '0';
        }
      // })
    },
    //附件展示回显
    showImg(){
      const params = {
        wxid:this.wxid
      }
      upFileShow(params).then(res=>{//wx_code:0-存储成功,其他为错误 wx_code:1[没有读取到图片数据]"
        if(res.wx_code=='0'){
          this.fileList = res.imgurl;
          this.upFile = res.imgurl.length
        }else{
          this.fileList = [];
          this.upFile = '0'
        }
      })
    },
    showDataDisable(data){//已提交过后不能再次提交且信息不能修改
      if(data.approval_status=='0' || data.approval_status=='3'){
        Toast.success({
          message: "审核中",
          duration:'3000'
        });
      }else if(data.approval_status=='1'){
        Toast.success({
          message: "审核通过",
          duration:'3000'
        });
      }
        this.userName = data.cus_name;
        this.vin = data.vin;
        this.wxid = data.wxid;
        this.contractNum = data.con_no;
        this.accountName = data.account_name;
        this.amount = data.amount;
        this.currentTime = data.apply_time;
        this.date = data.pay_day;
        this.isReadonly = true;
        //data.approval_status,//0审核中/1通过/2退回/3退回再次申请
    },
    onDateConfirm(date) {
      this.showCalendar = false;
      this.date = this.formatDate(date);
    },
    onContractConfirm(value) {
      this.fileList = [];
      this.upFile = '0'
      this.$store.commit('backFlag', '');
      this.$store.commit('imgUrlList', []);
      var dataList = this.dataList;
      var wxCode = '';
      var index = '';
      this.contractNum = value;
      this.showContractPicker = false;
      this.dataList.map((item,i) => {
        if(item.con_no == value){
          wxCode = item.wx_code;
          index = i
        }
      });
      this.vin = this.dataList[index].vin;
      this.userName = this.dataList[index].cus_name;
      this.wxid = this.dataList[index].wxid;
      if(wxCode=='5'){
        this.showDataDisable(dataList[index]);
        this.showImg();
      }else if(wxCode=='0'){
        var myDate = new Date();
        this.accountName = '';
        this.amount = '';
        this.date = this.formatDate(myDate.getTime());
        this.currentTime = this.formatDate(myDate.getTime(),true);
        this.isReadonly = false;
        // this.upFile = '0';
      }
    },
    handdleUpfile(){
      const params = {
        userName:this.userName,
        vin:this.vin,
        date:this.date,
        accountName:this.accountName,
        amount:this.amount,
      }
      this.$store.commit('writeData', params);
      this.$store.commit('fileList', this.fileList);
      this.$router.push({ 
        path: '/upFile',
        query: { 
          conNo: this.contractNum,
          isReadonly:this.isReadonly,
          imgtype:this.imgtype
        }});
    },
    onSubmit(values) {
      // #card_id, access_token, con_no, pay_day, vin, account_name, amount, apply_time 对公还款 
      var myDate = new Date();
      this.currentTime = this.formatDate(myDate.getTime(),true);
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId,
        con_no:values.contractNum,
        pay_day:values.date,
        vin:values.vin,
        account_name:values.accountName,
        amount:values.amount,
        apply_time:this.currentTime,
        imgurl:this.$store.state.imgUrlList
      }
      Dialog.confirm({
        message: '申请后无法撤销\n请确认是否提交',
      })
      .then(() => {
        corporateRepaymentSub(params).then(res=>{
          if(res.wx_code=='0'){
            Toast({
              message: '提交成功！',
              icon: require('@/assets/img/bindSuccess.png'),
              duration:'3000'
            });
            this.$store.commit('imgUrlList', []);
            this.$store.commit('backFlag', '');
            this.$store.commit('writeData', {});
            // this.getDetail();
            corporateRepaymentDetail({access_token:this.accessToken,card_id:this.cardId}).then(res=>{
              this.columns = [];
              this.dataList = res.detail_data;
              res.detail_data.map(item => {
                this.columns = [...this.columns,item.con_no]
              });
              this.$store.commit('corporateDataList', res.detail_data);
              this.onContractConfirm(values.contractNum);
            })
          }else{
            Toast({
              message: res.msg,
              icon: require('@/assets/img/bindError.png'),
              duration:'3000'
            });
            this.$store.commit('imgUrlList', []);
            this.$store.commit('backFlag', '');
            this.$store.commit('writeData', {});
          }
        })
      })
      .catch(() => {
        // on cancel
      });
    }
  },
};
</script>
<style scoped>
.wrap{
    padding: 20px 0px;
    background-color: #fff;
  }
.van-button--default{
  border: 0px;
}
.tips{
  color:#808080;
  font-size:13px;
  margin-top: 20px;
}
.tips p{
    margin: 6px 15px;
}
</style>
<style>
.wrap .van-field__control{
  color: #666666 !important;
}
textarea{
  overflow:hidden;
}
/* #app{
  background: #fff !important;
} */
.spanUpfile{
  width: 100%;
}
</style>